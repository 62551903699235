/**
 * 格式化显示文件大小
 * @param fileSize
 * @param idx
 */
export function formatFileSize(fileSize: number, idx = 0): string {
    const units = ['B', 'KB', 'MB', 'GB'];

    if (fileSize < 1024 || idx === units.length - 1) {
        return fileSize.toFixed(2).replace(/\.0+$/, '') + units[idx];
    }

    return formatFileSize(fileSize / 1024, ++idx);
}
