import BaseCRUDQService from '@common-src/service3/base-crudq.service';
import http from '@common-src/service3/http/axios';
import { ApiMap } from './api-map';
import { DeiverInstanceLogEntityModel, DriveNodeEntityModel, DriveNodeQueryModel } from '@common-src/entity-model/drive-node-entity';
import { get, post } from '@common-src/service/request';

export class DriveNodeService extends BaseCRUDQService<DriveNodeEntityModel, DriveNodeQueryModel> {
    constructor(baseUrl = DriveNodeEntityModel.baseUrl) {
        super(baseUrl, DriveNodeEntityModel);
    }

    static async selectOption() {
        const url = `${DriveNodeEntityModel.baseUrl}/select`;
        const res = await post(url, { ip: '', name: '' });
        return _.map(res, item => {
            return {
                label: item.name,
                value: item.id
            };
        });
    }

    static async uploadNodeZip(importModel: any) {
        const url = `${DriveNodeEntityModel.baseUrl}/upload`;
        const params = importModel.toService();
        const res = await post(url, params, { headers: { 'Content-Type': 'multipart/form-data', timeout: 600000 } });
        return res;
    }

    static async uploadNodeSDK(importModel: any) {
        const url = `${DriveNodeEntityModel.baseUrl}/uploadSDK`;
        const params = importModel.toService();
        const res = await post(url, params, { headers: { 'Content-Type': 'multipart/form-data', timeout: 600000 } });
        return res;
    }
}

export async function getSSHOptions(val: any): Promise<Array<{ value: string, label: string }>> {
    if (_.isEmpty(val.join(''))) {
        return null;
    }
    const res = await Promise.resolve({
        data: _.map(val, item => {
            return {
                value: item,
                label: item
            };
        })
    });
    return res.data;
}

/**
 * 节点文件和驱动实例文件下载
 * @param param
 * @returns
 */
export async function downloadNodeAndDriverInstanceFile(param: any): Promise<any> {
    const url = `${DriveNodeEntityModel.baseUrl}/download`;
    const res = await get(url, param, { timeout: 600000 });
    return res;
}

/**
 * 获取下一级文件目录
 * @param param
 * @returns
 */
export async function getNextDirectory(id: string, param: any): Promise<any> {
    const url = `${DriveNodeEntityModel.baseUrl}/listDirectory/${id}`;
    const res = await get(url, param);
    return _.map(res, item => {
        return new DeiverInstanceLogEntityModel().toModel(item);
    });
}
