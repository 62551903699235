import { Component } from 'vue-property-decorator';
import TableComponent from './table-component';
import { TableTreeType } from '@common-src/model/enum';
import { getNextDirectory } from '@common-src/service3/driver-node';

interface TreeEntityModel {
    id: string;
    children: Array<TreeEntityModel>;
    expand: boolean;
    parent: TreeEntityModel;
    childrenPage: number;
    childrenTotalCount: number;
    uuid: string;
    hasChildren: boolean;
}

interface TreeEntityQueryModel {
    toService(): any;
}

interface TreeEntityService {
    query(model: any, page: number, size: number);
    unlink(id: string, parentId: string);
}

@Component
export default class TableTreeComponent extends TableComponent<any, any> {
    expandedRowKeys: Array<string> = [];
    treeEntityService: TreeEntityService = null;
    tableTreeType: TableTreeType = TableTreeType.DEVICE;

    getChildren(model: TreeEntityModel, isLoadMore: boolean, isReload: boolean) {
        switch (this.tableTreeType) {
            case TableTreeType.DEVICE:
                this.getDeviceChildren(model, isLoadMore, isReload);
                break;
            case TableTreeType.DRIVERINSTANCELOG:
                this.getDriverInstanceLogChildren(model, isLoadMore, isReload);
                break;
            default:
                this.getDeviceChildren(model, isLoadMore, isReload);
                break;
        }
    }

    linkChildClick(model: TreeEntityModel) {
        (this.$refs.formChildDialog as any).dialogOpen(model.id, model['typeId'], model['identifier']);
    }
    unlinkChildClick(model: TreeEntityModel) {
        if (!model.parent) {
            return;
        }
        return this.treeEntityService.unlink(model.id, model.parent.id).then(() => {
            const index = _.findIndex(model.parent.children, item => item.id === model.id);
            if (index > -1) {
                model.parent.children.splice(index, 1);
                model.parent.childrenTotalCount--;
                if (model.parent.childrenTotalCount === 0) {
                    model.parent.hasChildren = false;
                }
            }
            model.parent = null;
            return model;
        });
    }
    formChildDialogOK(parentDeviceModel: TreeEntityModel) {
        // this.getList();
        parentDeviceModel.hasChildren = true;
        this.getChildren(parentDeviceModel, false, true);
    }
    expandClick(model: TreeEntityModel) {
        if (model.children && model.children.length > 0) {
            this.initExpand(model, false, false);
        } else {
            this.getChildren(model, false, false);
        }
    }
    loadMoreClick(model?: TreeEntityModel): any {
        this.getChildren(model, true, false);
    }

    getDeviceChildren(model: TreeEntityModel, isLoadMore: boolean, isReload: boolean) {
        const deviceQueryModel: TreeEntityQueryModel = {
            toService: () => {
                return {
                    params: {
                        parentId: model.id
                    }
                };
            }
        };
        let page = null;
        if (isReload) {
            page = 1;
        } else {
            page = model.childrenPage + 1;
        }
        // this.treeEntityService.query(deviceQueryModel as any, page, JTL.CONSTANT.PAGE_SIZE).then(res => {
        return this.treeEntityService.query(deviceQueryModel as any, page, this.pageSize).then(res => {
            if (isLoadMore) {
                if (_.get(res, 'items', []).length === 0) {
                    this.showMessageInfo('没有更多了...');
                    return;
                }
                if (!model.children) {
                    model.children = [];
                }
            }
            const children = _.map(_.get(res, 'items', []), item => {
                item.parent = model;
                return item;
            });
            if (isLoadMore) {
                model.children.push(...children);
            } else {
                model.children = children;
            }
            model.childrenTotalCount = _.get(res, 'Total', 0);
            model.childrenPage = page;
            this.initExpand(model, isLoadMore, isReload);
        });
    }

    /**
     * 获取驱动实例日志子列表
     */
    getDriverInstanceLogChildren(model: any, isLoadMore: boolean, isReload: boolean) {
        return getNextDirectory(model.nodeId, { subPath: model.subPath }).then(res => {
            if (isLoadMore) {
                if (res.length === 0) {
                    this.showMessageInfo('没有更多了...');
                    return;
                }
                if (!model.children) {
                    model.children = [];
                }
            }
            const children = _.map(res, item => {
                item.parent = model;
                return item;
            });
            console.log('model', children);
            if (isLoadMore) {
                model.children.push(...children);
            } else {
                model.children = children;
            }
            this.initExpand(model, isLoadMore, isReload);
        });
    }

    /**
     * 刷新树形列表
     */
    async refreshClick() {
        this.treeEntityService.query(this.queryModel, this.page, this.pageSize || JTL.CONSTANT.PAGE_SIZE).then(async(res) => {
            if (res && res.items) {
                if (!_.isUndefined(res.total)) {
                    this.total = res.total;
                } else if (!_.isUndefined(res.Total)) {
                    this.total = res.Total;
                }
                const refreshListData = res.items;
                const listDataTemp = [];
                for (let index = 0; index < refreshListData.length; index++) {
                    const originModel = _.find(this.listData, item => item.id === refreshListData[index].id);
                    if (originModel) {
                        // 原始数据中没有新增的数据
                        const newDeviceModel = await this.reInitDataList(originModel, refreshListData[index]);
                        listDataTemp.push(newDeviceModel);
                    } else {
                        // 有新增的数据
                        listDataTemp.push(refreshListData[index]);
                    }
                }
                // for (let index = 0; index < this.listData.length; index++) {
                //     const newDeviceModel = await this.reInitDataList(this.listData[index], index, refreshListData);
                //     listDataTemp.push(newDeviceModel);
                // }
                this.listData = listDataTemp;
            } else {
                this.listData = [];
            }
        });
        this.selectedRowKeys = [];
    }

    /**
     * 刷新子列表
     * @param originModel 原始数据
     * @param newModel 刷新之后的数据
     */
    async reInitDataList(originModel: any, newModel: any) {
        if (originModel.expand) {
            await this.getChildren(newModel, false, true);
            if (newModel.children && newModel.children.length > 0) {
                // const listDataTemp = [];
                // for (let childIndex = 0; childIndex < newModel.children.length; childIndex++) {
                //     const originChildModel = _.find(originModel.children, item => item.id === newModel[childIndex].id);
                //     if (originChildModel) {
                //         // 原始数据中没有新增的数据
                //         const newChildModel = await this.reInitDataList(originModel.children[childIndex], newModel.children[childIndex]);
                //         listDataTemp.push(newChildModel);
                //         // await this.reInitDataList(originModel.children[childIndex], newModel.children[childIndex]);
                //     } else {
                //         // 有新增的数据
                //         listDataTemp.push(newModel.children[childIndex]);
                //     }
                // }
                // // 重置原始数据中的children，刷新列表
                // originModel.children = listDataTemp;

                const listDataTemp = [];
                for (let childIndex = 0; childIndex < newModel.children.length; childIndex++) {
                    const originChildModel = _.find(originModel.children, item => item.id === newModel.children[childIndex].id);
                    if (originChildModel) {
                        const newChildModel = await this.reInitDataList(originChildModel, newModel.children[childIndex]);
                        listDataTemp.push(newChildModel);
                    } else {
                        listDataTemp.push(newModel.children[childIndex]);
                    }
                }
                newModel.children = listDataTemp;
            }
            // originModel = newModel;
            // return originModel;
        }
        return newModel;
    }

    private initExpand(model: TreeEntityModel, isLoadMore: boolean, isReload: boolean) {
        if (!isLoadMore) {
            if (isReload) {
                model.expand = true;
            } else {
                model.expand = !model.expand;
            }
        }
        if (model.expand) {
            if (this.expandedRowKeys.indexOf(model.uuid) === -1) {
                this.expandedRowKeys.push(model.uuid);
            }
        } else {
            const index = _.findIndex(this.expandedRowKeys, item => item === model.uuid);
            if (index > -1) {
                this.expandedRowKeys.splice(index, 1);
            }
        }
    }
}
