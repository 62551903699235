import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlUploadModel, FormControlOptionModel, FormControlTextModel } from '@common-src/model/form-control';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { QueryPageModel } from '@common-src/model/query-model';
import { getSSHOptions } from '@common-src/service3/driver-node';
import { formatFileSize } from '@common-src/utils/number_util';
import moment, { Moment } from 'moment';
import { NodeImportType } from '@common-src/model/enum';

const minDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
const maxDate = moment().format('YYYY-MM-DD');

export class DriveNodeEntityModel extends BaseEntityModel {
    static baseUrl = `${IOT_BASE_REQUEST_PATH}/node`;

    @FormControl({
        label: '节点名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlOptionModel)
    name: string = undefined;

    @FormControl({
        label: '网卡',
        type: FormControlType.ARRAY_STRING,
        required: true,
        placeholder: '请输入网卡IP地址',
        relationKeys: ['sshIp']
    } as FormControlOptionModel)
    networkIps: Array<string> = [''];

    @FormControl({
        label: 'SSH地址',
        type: FormControlType.SELECT,
        optionsPromise: getSSHOptions,
        optionsPromiseParam: 'networkIps',
        required: true
    } as FormControlOptionModel)
    sshIp: string = undefined;

    @FormControl({
        label: '端口',
        type: FormControlType.TEXT,
        required: true
    } as FormControlOptionModel)
    port: number = 22;

    @FormControl({
        label: '账号',
        type: FormControlType.TEXT,
        required: true
    } as FormControlOptionModel)
    username: string = undefined;

    @FormControl({
        label: '密码',
        type: FormControlType.TEXT,
        inputType: 'password',
        required: true
    } as FormControlTextModel)
    password: string = undefined;

    default: boolean = undefined;

    static getTableColumns() {
        const tableColumns = [
            {
                title: '节点名称',
                dataIndex: 'name',
                width: 150,
                ellipsis: true
            },
            {
                title: '网卡',
                dataIndex: 'networkIps',
                ellipsis: true,
                customRender: (text) => {
                    return text?.join('; ') || text;
                }
            },
            {
                title: 'SSH地址',
                width: 150,
                dataIndex: 'sshIp',
                ellipsis: true
            },
            {
                title: '端口',
                dataIndex: 'port',
                width: 120,
                ellipsis: true
            },
            {
                title: '账号',
                dataIndex: 'username',
                ellipsis: true
            },
            {
                title: '是否默认节点',
                dataIndex: 'default',
                width: 120,
                customRender: (text) => {
                    return text ? '是' : '否';
                }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
        return tableColumns;
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }
}

export class DriveNodeQueryModel extends QueryPageModel {
    @QueryControl({
        label: '节点名称',
        type: QueryControlType.TEXT,
        span: 6,
        index: 1
    })
    name: string = JTL.CONSTANT.ALL_OPTION.value;

    toService() {
        return super.getParams();
    }
}

export class ImportJarZipEntityModel extends BaseEntityModel {
    nodeIds: string = undefined;
    type: any = NodeImportType.GATEWAY;
    constructor(nodeIds?: string, type?:any) {
        super();
        this.nodeIds = nodeIds;
        this.type = type;
    }

    @FormControl({
        label: '上传网关程序包',
        type: FormControlType.UPLOAD,
        accept: '.jar,.zip',
        maxFileNumber: 1,
        notUploadOss: true,
        required: true,
        invisibleFunction(model) {
            return model.type !== NodeImportType.GATEWAY;
        }
    } as FormControlUploadModel)
    file: Array<any> = undefined;

    @FormControl({
        label: '上传SDK文件',
        type: FormControlType.UPLOAD,
        accept: '.rar,.zip',
        maxFileNumber: 1,
        notUploadOss: true,
        required: true,
        invisibleFunction(model) {
            return model.type !== NodeImportType.SDK;
        }
    } as FormControlUploadModel)
    fileSdk: Array<any> = undefined;

    /**
     * 是否需要解压
     */
    unzip: boolean = true;

    /**
     * 边缘网关压缩包
     */
    edgeGateway: boolean = true;

    toService() {
        const formData = new FormData();
        if (this.type === NodeImportType.GATEWAY) {
            formData.append('file', _.get(this.file, '[0].file'));
            formData.append('unzip', this.unzip.toString());
            formData.append('edgeGateway', this.edgeGateway.toString());
        } else {
            formData.append('file', _.get(this.fileSdk, '[0].file'));
        }
        formData.append('nodeIds', this.nodeIds);

        return formData;
    }
}

// 日志
export class DeiverInstanceLogEntityModel extends BaseEntityModel {
    id: string = undefined;
    children: Array<DeiverInstanceLogEntityModel> = undefined;
    childrenTotalCount: number = 0;
    childrenPage: number = 0;
    parent: DeiverInstanceLogEntityModel;
    parentId: string = undefined;
    expand: boolean = false;
    extended: string = undefined;

    name: string = undefined;
    modifyTime: string = undefined;
    dir: boolean = undefined;
    size: number = 0;
    permissions: string = undefined;

    nodeId: string = undefined;
    subPath: string = undefined;
    currentPath: string = undefined;

    link: string = undefined;
    get Children() {
        return this.children;
    }

    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name',
                ellipsis: true,
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '修改时间',
                dataIndex: 'modifyTime',
                ellipsis: true
            },
            {
                title: '是否目录',
                dataIndex: 'dir',
                ellipsis: true,
                customRender: (text, record, index) => {
                    return text ? '是' : '否';
                }
            },
            {
                title: '文件大小',
                dataIndex: 'size',
                ellipsis: true,
                customRender: (text, record, index) => {
                    return formatFileSize(text);
                }
            },
            {
                title: '当前路径',
                dataIndex: 'currentPath',
                ellipsis: true
            },
            {
                title: '操作',
                dataIndex: 'action',
                ellipsis: true,
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json: any) {
        super.toModel(json);
        if (this.dir) {
            this.children = [];
        }
        return this;
    }
}

const checkDisabledDate = (currentDate: any) => {
    const dateStr = currentDate.format('YYYY-MM-DD');
    return dateStr < minDate || dateStr > maxDate;
};

export class DeiverInstanceLogQueryModel extends QueryPageModel {
    id: string = undefined;
    constructor(id: string) {
        super();
        this.id = id;
    }
    @QueryControl({
        label: '日期范围',
        type: QueryControlType.SELECT_DATE,
        span: 8,
        index: 1,
        isRange: true,
        disabledDate: (currentDate) => checkDisabledDate(currentDate),
        ranges: {
            '今天': JTL.CONSTANT.TODAY(),
            '昨天': JTL.CONSTANT.YESTERDAY(),
            '上周': JTL.CONSTANT.LAST_WEEK(),
            '过去7天': JTL.CONSTANT.LAST_7_DAY(),
            '过去30天': JTL.CONSTANT.LAST_30_DAY()
        }
    })
    dateRange: Array<Moment> = [];

    toService() {
        const data: any = super.getParams();
        data.params.startTime = this.dateRange[0]?.startOf('day').toDate().getTime();
        data.params.endTime = this.dateRange[1]?.endOf('day').toDate().getTime();
        delete data.params.dateRange;
        return data;
    }
}
